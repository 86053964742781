import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function PrivacyPolicy() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <div className="row static-row">
          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '20px', background: '#fff0' }}>
            
          
                <p class="last-updated font-en-text">Last Update: June 4, 2024</p>

                <h1 className='font-en-heading'>Privacy Policy</h1>

                <p className='font-en-text'>JOVIA values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or use our services.</p>

                <h2 className='font-en-heading'>Information We Collect</h2>

                <ul className='font-en-text'>
                  <li><strong>Personal Information:</strong> Includes your name, email address, phone number, payment information, and delivery details when you make a purchase or create an account.</li>
                  <li><strong>Usage Data:</strong> Information about your interactions with our website, such as IP address, browser type, and pages visited.</li>
                </ul>

                <h2 className='font-en-heading'>How We Use Your Information</h2>

                <ul className='font-en-text'>
                  <li><strong>Service Provision:</strong> To process transactions, deliver products, manage your account, and provide customer support.</li>
                  <li><strong>Service Improvement:</strong> To analyze usage data and enhance website functionality and user experience.</li>
                  <li><strong>Marketing:</strong> With your consent, to send promotional materials, special offers, and updates. You can opt out at any time.</li>
                </ul>

                <h2 className='font-en-heading'>Sharing Your Information</h2>

                <ul className='font-en-text'>
                  <li><strong>Third-Party Service Providers:</strong> We share information with trusted partners for processing payments, delivering products, providing customer support, and conducting marketing activities.</li>
                  <li><strong>Legal Requirements:</strong> We may disclose information to comply with legal obligations, enforce our terms and conditions, or protect our rights.</li>
                </ul>

                <h2 className='font-en-heading'>Security</h2>

                <p className='font-en-text'>We implement robust security measures, including encryption and secure servers, to protect your data from unauthorized access, alteration, or destruction.</p>

                <h2 className='font-en-heading'>Cookies</h2>

                <p className='font-en-text'>We use cookies to personalize your experience, track usage patterns, and analyze traffic. You can manage your cookie preferences through your browser settings.</p>

                <h2 className='font-en-heading'>Your Rights</h2>

                <ul className='font-en-text'>
                  <li><strong>Access:</strong> Request access to the personal information we hold about you.</li>
                  <li><strong>Correction:</strong> Request corrections to any inaccurate or incomplete information.</li>
                  <li><strong>Deletion:</strong> Request deletion of your personal information, subject to legal obligations.</li>
                  <li><strong>Opt-out:</strong> Withdraw your consent for data processing or opt-out of marketing communications.</li>
                </ul>

                <h2 className='font-en-heading'>International Data Transfers</h2>

                <p className='font-en-text'>We may transfer your data to countries outside of your residence, including to our service providers located abroad. We ensure these transfers comply with applicable data protection laws.</p>

                <h2 className='font-en-heading'>Children's Privacy</h2>

                <p className='font-en-text'>Our services are not directed at children under 13, and we do not knowingly collect personal information from children.</p>

                <h2 className='font-en-heading'>Changes to This Policy</h2>

                <p className='font-en-text'>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>

                <h2 className='font-en-heading'>Contact Us</h2>

                <p className='font-en-text'>If you have any questions or concerns about our Privacy Policy or data handling practices, please <a href="mailto:support@jovia.com">contact us here</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default PrivacyPolicy;

