import React, { useEffect, useState } from 'react';
import './index.scss';
import { useSelector } from "react-redux";
// import AR from './AR';
import EN from './EN';
function Requests() {
  const { languageCode } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <>
      {languageCode === 'en' ? <EN /> : <EN />}
    </>
  );
}

export default Requests;

