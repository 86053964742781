import React, { useEffect } from 'react';
import './index.scss';
import { useSelector } from "react-redux";
import AR from './AR';
import EN from './EN';
function TermsConditions() {

  const { languageCode } = useSelector((state) => state.auth);

  return (
    <>
      {languageCode === 'en' ? <EN /> : <AR />}
    </>
  );
}

export default TermsConditions;

