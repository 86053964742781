import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Pages/Home';
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import About from "./Pages/About";
import Partner from "./Pages/Partner";
import Pages from './Layout/Pages';
import Contact from "./Pages/Contact";
import Requests from "./Pages/Requests";
import NotFound from './NotFound';
function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={['/terms-and-conditions', '/:language/terms-and-conditions']}>
            <Pages Components={TermsConditions} footer={true} header={true} />
          </Route>
          <Route exact path={['/privacy-policy', '/:language/privacy-policy']}>
            <Pages Components={PrivacyPolicy} footer={true} header={true} />
          </Route>
          <Route exact path={['/about', '/:language/about']}>
            <Pages Components={About} footer={true} header={true} />
          </Route>
          <Route exact path={['/partner', '/:language/partner']}>
            <Pages Components={Partner} footer={true} header={true} />
          </Route>
          <Route exact path={['/contact-us', '/:language/contact-us']}>
            <Pages Components={Contact} footer={true} header={true} />
          </Route>
          <Route exact path={['/requests', '/:language/requests']}>
            <Pages Components={Requests} footer={true} header={true} />
          </Route>
          <Route exact path={["/", '/:language']}>
            <Pages Components={Home} footer={true} header={true} />
          </Route>
          <Route>
            <Pages Components={NotFound} footer={true} header={true} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
