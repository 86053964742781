import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function PrivacyPolicy() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <div className="row static-row">
          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '20px', background: '#fff0' }}>
              <h1 className='font-ar-heading'>الشروط والأحكام</h1>

              <h2 className='font-ar-heading'>المقدمة</h2>
              <p className='font-ar-text'>مرحبًا بكم في جوڤيا. بشرائكم واستخدامكم لقسائم الهدايا الخاصة بنا، فإنكم توافقون على الالتزام بالشروط والأحكام التالية. يُرجى قراءة هذه الشروط بعناية.</p>

              <h2 className='font-ar-heading'>سياسة عدم الاسترجاع أو الاستبدال</h2>
              <p className='font-ar-text'>جميع مبيعات الهدايا والقسائم نهائية. يتم تسليم كل قسيمة في تجربة صندوق هدايا فريدة، وبمجرد فتح الصندوق، تعتبر الهدية مستخدمة. لذلك، لا تقدم جوڤيا استرجاعًا أو استبدالًا أو ردًا على أي قسيمة تم شراؤها. في حالات الطوارئ القصوى، قد ندرس إمكانية تقديم حل. إذا كنت تواجه ظرفًا طارئًا، يجب عليك تقديم طلب مرفق بأدلة على الحالة الطارئة. ستقوم جوڤيا بتقييم الطلب والنظر في إمكانية إيجاد حل بناءً على الأسباب المقدمة.</p>

              <h2 className='font-ar-heading'>صلاحية القسيمة والتذكيرات</h2>
              <p className='font-ar-text'>كل قسيمة هدية لها تاريخ انتهاء محدد. سيتم تذكير مستلم القسيمة ثلاث مرات قبل انتهاء صلاحيتها. إذا لم يتم استخدام القسيمة بحلول تاريخ انتهاء الصلاحية، فسيتم فقدان القسيمة ولن يتم تقديم أي استرجاع او تبديل أو تمديد.</p>

              <h2 className='font-ar-heading'>مسؤوليات المرسل والمستلم</h2>
              <p className='font-ar-text'>يجب على كل من المرسل والمستلم قراءة وفهم الشروط والأحكام المرتبطة بكل قسيمة. يشمل ذلك، على سبيل المثال، فترة الصلاحية، تعليمات الاستخدام، وأي شروط خاصة أخرى موضحة لكل قسيمة.</p>

              <h2 className='font-ar-heading'>استخدام القسائم</h2>
              <p className='font-ar-text'>قسائم الهدايا مخصصة للاستخدام من قبل المستلم المعين فقط. لا يمكن نقل القسائم إلا بناءً على تقدير مقدم الخدمة الذي قد يسمح بنقلها إلى مستخدم آخر. لا يمكن استبدال القسائم بالنقود. يجب على المستلم اتباع التعليمات المحددة المقدمة لاسترداد القسيمة.</p>

              <h2 className='font-ar-heading'>أوصاف المنتج</h2>
              <p className='font-ar-text'>تحاول شركة هداية الذكريات للتجارة (جوڤيا) أن تكون دقيقة قدر الإمكان. ومع ذلك، يتم تقديم معظم معلومات المنتج المرتبطة بالتطبيق من قبل أطراف ثالثة، وتعتمد جوڤيا على دقتها وعدم تضليلها. وفقًا لذلك، لا تضمن جوڤيا أن يكون التطبيق، أوصاف المنتجات، والمحتويات الأخرى على هذا الموقع كاملة، دقيقة، غير مضللة، موثوقة، حديثة أو خالية من الأخطاء.</p>

              <h2 className='font-ar-heading'>التوافق مع الأجهزة</h2>
              <p className='font-ar-text'>لا تضمن شركة هداية الذكريات للتجارة (جوڤيا) أن يكون التطبيق متوافقًا أو قابلًا للتشغيل مع جهازك أو أي جهاز آخر، أو معدات مثبتة على جهازك أو مستخدمة معه.</p>

              <h2 className='font-ar-heading'>التغييرات على التطبيق</h2>
              <p className='font-ar-text'>قد نصدر إصدارات محدثة من التطبيق وقد نقوم تلقائيًا بترقية نسخة التطبيق على موقعنا. قد نقوم بتغيير، توسيع، وتحسين التطبيق. قد نتوقف في أي وقت عن تشغيل جزء أو كل أو تعطيل جوانب معينة من التطبيق بشكل انتقائي.</p>

              <h2 className='font-ar-heading'>إخلاء المسؤولية عن الضمانات وتحديد المسؤولية</h2>
              <p className='font-ar-text'>إلى أقصى حد مسموح به بموجب القانون المعمول به، تخلي شركة هداية الذكريات للتجارة (جوڤيا) مسؤوليتها عن جميع الضمانات، الصريحة أو الضمنية، بما في ذلك على سبيل المثال لا الحصر، الضمانات الضمنية بعدم التعدي وقابلية التسويق والملاءمة لغرض معين.</p>

              <h2 className='font-ar-heading'>التعويض</h2>
              <p className='font-ar-text'>توافق على تعويض، والدفاع عن، وإبقاءنا وشركاؤنا، ووكلائنا، ومسؤولينا، ومديرينا، وموظفينا، ومقاولينا الفرعيين، وخلفائنا ومتنازلينا، وموردي المعلومات والوثائق من الأطراف الثالثة، والمحامين، والمعلنين، ومقدمي المنتجات والخدمات، والشركات التابعة لنا خالية من أي مسؤولية، أو خسارة، أو مطالبة، أو نفقة، بما في ذلك أتعاب المحاماة المعقولة، المتعلقة بانتهاكك لهذه الشروط أو استخدامك للتطبيق.</p>

              <h2 className='font-ar-heading'>التغييرات على الشروط والأحكام</h2>
              <p className='font-ar-text'>تحتفظ جوڤيا بالحق في تعديل هذه الشروط والأحكام في أي وقت دون إشعار مسبق. ستكون أي تغييرات فعالة فور نشرها على موقعنا. يشير استمرار استخدامك للقسائم بعد أي تغييرات إلى قبولك للشروط الجديدة.</p>

              <h2 className='font-ar-heading'>سياسة الخصوصية</h2>
              <p className='font-ar-text'>يخضع استخدامك للتطبيق أيضًا لسياسة الخصوصية الخاصة بنا. يُرجى مراجعة سياسة الخصوصية الخاصة بنا للحصول على معلومات حول كيفية جمعنا واستخدامنا وحماية بياناتك الشخصية.</p>

              <h2 className='font-ar-heading'>حسابات المستخدمين</h2>
              <p className='font-ar-text'>إذا قمت بإنشاء حساب على تطبيقنا، فأنت مسؤول عن الحفاظ على أمان حسابك وجميع الأنشطة التي تحدث تحت حسابك. يجب عليك إخطار جوڤيا فورًا بأي استخدام غير مصرح به لحسابك.</p>

              <h2 className='font-ar-heading'>الملكية الفكرية</h2>
              <p className='font-ar-text'>جميع المحتويات الموجودة على التطبيق، بما في ذلك النصوص، الرسومات، الشعارات، الصور، والبرامج، هي ملك لشركة هداية الذكريات للتجارة (جوڤيا) أو المرخصين لها وهي محمية بموجب قوانين حقوق النشر، العلامات التجارية، وحقوق الملكية الفكرية الأخرى.</p>

              <h2 className='font-ar-heading'>قابلية الفصل</h2>
              <p className='font-ar-text'>إذا تم اعتبار أي بند من هذه الشروط غير قابل للتنفيذ، ستظل البنود المتبقية سارية وفعالة.</p>

              <h2 className='font-ar-heading'>القانون الحاكم</h2>
              <p className='font-ar-text'>تخضع هذه الشروط والأحكام لقوانين المملكة العربية السعودية. سيتم حل أي نزاعات تنشأ عن أو تتعلق بهذه الشروط والأحكام حصريًا في محاكم المملكة العربية السعودية.</p>

              <h2 className='font-ar-heading'>اللغة الحاكمة</h2>
              <p className='font-ar-text'>تُقدم هذه الشروط والأحكام باللغتين الإنجليزية والعربية. في حال وجود أي اختلاف بين النسختين، تسود النسخة العربية.</p>

              <h2 className='font-ar-heading'>معلومات الاتصال</h2>
              <p className='font-ar-text'>لأي أسئلة أو مخاوف بشأن هذه الشروط والأحكام، يُرجى الاتصال بنا على: البريد الإلكتروني</p>

              <p className='font-ar-text'>بشراء واستخدام قسائم الهدايا الخاصة بجوڤيا، فإنك تقر بأنك قد قرأت وفهمت ووافقت على هذه الشروط والأحكام.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

