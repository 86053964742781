import React, { useEffect, useState } from 'react';
import './index.scss';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/joy/Button';

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
function AR() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)


  async function createInquiryFrom(data) {
    if (isValidPhoneNumber(getPhone)) {
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <div className="row static-row">


          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>انضم إلى منصة جوڤيا</div>
              <p className='font-ar-text' style={{ fontSize: '1.2rem' }}>
                انضم إلى منصتنا لعرض تجاربك المميزة أمام جمهور متميز يقدّر الهدايا الفريدة والمبتكرة. املأ النموذج أدناه ودعنا نعمل معًا لخلق لحظات لا تُنسى لعملائنا.
              </p>
            </div>
          </div>




          <div className="col-md-10" style={{ padding: '0px' }}>
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0' }}>

              <div className="row">
                <div className="col-md-12">
                  <h3 className='font-ar-text' style={{ padding: '20px 0px 20px 0px', textAlign: 'start', fontSize: '1.5rem' }}>
                    {t('نموذج طلب الشراكة')}
                    <br />
                  </h3>
                </div>
              </div>

              <form
                className='font-ar-text'
                onSubmit={handleSubmit(createInquiryFrom)}
              >
                {/* <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey=""
                        /> */}

                <div className="row">
                  <div className="col-md-12">
                    <div style={{ fontSize: '1.2rem', fontWeight: 600, marginBottom: '5px' }}>معلومات العمل</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label >{t('اسم النشاط التجاري')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label className='pf-break'>{t('نوع النشاط التجاري ')}
                        <small><i>(مثل: سبا، مطعم، مغامرات خارجية، فعاليات مائية، إقامة فاخرة، وغيرها)</i></small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label>{t('الموقع الإلكتروني للنشاط التجاري')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label" >
                      <label >{t('رابط وسائل التواصل الاجتماعي')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: '10px' }}>
                    <div className="form-group">
                      <label for="123">{t('هل لديك سجل تجاري؟ ')}  </label>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ lineHeight: 0.6 }}>
                    <div className="form-group form-group-check">
                      <input id="123yes" type='checkbox' />
                      <label for="123yes">{t('نعم')} </label>
                    </div>
                    <div className="form-group form-group-check">
                      <input id="123no" type='checkbox' />
                      <label for="123no">{t('لا')} </label>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginBottom: '10px' }}>
                    <div className="form-group">
                      <label for="1234">{t('هل لديك شهادة تسجيل ضريبة القيمة المضافة؟ ')} </label>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ lineHeight: 0.6 }}>
                    <div className="form-group form-group-check">
                      <input id="123no2" type='checkbox' />
                      <label for="123no2">{t('نعم')} </label>
                    </div>
                    <div className="form-group form-group-check">
                      <input id="123yes2" type='checkbox' />
                      <label for="123yes2">{t('لا')} </label>
                    </div>
                  </div>

                </div>


                <div className="row">
                  <div className="col-md-12">
                    <div style={{ fontSize: '1.2rem', fontWeight: 600, marginBottom: '5px' }}>معلومات الاتصال</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label >{t('الاسم الكامل للشخص المسؤول')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label>{t('المسمى الوظيفي ')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label" >
                      <label >{t('عنوان البريد الإلكتروني ')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label>{t('رقم الهاتف ')} </label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={'SA'}
                        value={getPhone}
                        onChange={setPhone}
                      />
                      {getPhoneError &&
                        <>
                          {getPhone && isValidPhoneNumber(getPhone) ? '' :
                            <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                          }
                        </>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label" >
                      <label >{t('رقم الواتساب (لحجوزات العملاء) ')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                </div>




                <div className="row">
                  <div className="col-md-12">
                    <div style={{ fontSize: '1.2rem', fontWeight: 600, marginBottom: '5px' }}>تفاصيل التجربة والمعلومات التشغيلية</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-label">
                      <label>{t('وصف مختصر للتجربة التي تقدمها')}<br />
                        <small><i>(مثل: تجربة مطعم راقية مع طاولة الشيف، القفز بالمظلات، ورش عمل الفخار، وغيرها)</i></small>
                      </label>

                      <textarea
                        type="text"
                        className="form-control"
                        id="message"
                        aria-invalid={errors.message ? "true" : "false"}
                        {...register('message', { required: true })}>
                      </textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label>{t('ما الذي يجعل تجربتك فريدة؟')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label>{t('مدينت التجربة')} </label>
                      <select className='form-control'>
                        <option>Select</option>
                        <option>Riyadh</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label>{t('النطاق السعري المقدر للتجربة ')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group form-group-check">
                      <input id="12345" type='checkbox' />
                      <label for="12345">{t('هل التجربة موسمية؟ ')} </label>
                    </div>
                  </div>

                </div>



                <div className="row">

                  <div className="col-md-12">
                    <div className="form-group form-group-label">
                      <label>
                        ملاحظات إضافية
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="message"
                        aria-invalid={errors.message ? "true" : "false"}
                        {...register('message', { required: true })}>
                      </textarea>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-group-check">
                      <input id="12347" type='checkbox' />
                      <label for="12347">مربع اختيار: “أؤكد أنني مفوض لتقديم هذا الطلب نيابة عن نشاطي التجاري</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group form-group-check">
                      <input id="12346" type='checkbox' />
                      <label for="12346">مربع اختيار: “أوافق على أن يتم التواصل معي من قبل جوڤيا بخصوص هذه الشراكة</label>
                    </div>
                  </div>

                  <br />
                  <div className="col-md-12 Header-signin-button">
                    <div className="form-group">
                      <Button
                        variant='soft'
                        size='sm'
                        // disabled={createresult.isLoading}
                        style={{ width: '50%', height: '45px', marginBottom: '20px' }}
                        type="submit">
                        {/* {createresult.isLoading ? t('loading') : t('submit')} */}
                        {t('submit')}
                      </Button>

                    </div>
                  </div>
                </div>
              </form>

              <br /><br />
            </div>
          </div>



          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2rem' }}>عملية الشراكة</div>
              <br />
              <ol className='p-sec-text'>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>التقييم والمراجعة</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>بمجرد استلام طلبك، نقوم بتقييم نشاطك التجاري لضمان توافقه مع معايير الجودة والتميز الخاصة بجوڤيا.</span>
                </li>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>الموافقة والتعاقد</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>إذا تم قبول طلبك، سيتواصل معك أحد أعضاء فريقنا لإرسال العقد وتوقيعه، وجمع جميع المستندات القانونية المطلوبة.</span>
                </li>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>الإعداد والتجهيز</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>بعد توقيع العقد، سنعمل معك على تصميم العروض والتجارب التي سيتم عرضها في جوڤيا. يرجى التأكد من توفير صور ومقاطع فيديو عالية الجودة للخدمة التي تقدمها، مع تضمين عنصر بشري في الصور.</span>
                </li>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>الوصول إلى لوحة تحكم العلامة التجارية</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>بمجرد الانتهاء من إعداد العروض، ستحصل على اسم مستخدم وكلمة مرور للوصول إلى لوحة تحكم العلامة التجارية الخاصة بك، حيث يمكنك متابعة القسائم، والتحقق منها، والمطالبة بمستحقاتك.</span>
                </li>
              </ol>
            </div>
          </div>

          <br /><br />


          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2rem' }}>الأسئلة الشائعة FAQ </div>
              <br />
              <div className='p-sec-text'>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>
                    ما هي جوڤيا؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>جوڤيا هي منصة هدايا متخصصة في تقديم قسائم تجارب مميزة وفريدة، لتكون بديلاً مبتكرًا للهدايا التقليدية.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>لماذا يجب أن أصبح شريكًا مع جوڤيا؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>عند انضمامك إلى جوڤيا، ستتمكن من الوصول إلى جمهور مميز يبحث عن تجارب فاخرة وفريدة. ستستفيد أيضًا من رؤية أكبر، وإدارة سلسة للقسائم، ونظام دفع موثوق.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>من يمكنه أن يصبح شريكًا؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>نتعاون مع الأنشطة التجارية التي تقدم تجارب مميزة، مثل تناول الطعام الراقي، علاجات السبا، الأنشطة الترفيهية، ورش العمل، والخدمات الفريدة الأخرى. يشترط أن يفي نشاطك التجاري بمعايير الجودة الخاصة بنا ويقدم تجربة لا تُنسى للعملاء.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>هل هناك تكلفة للانضمام إلى جوڤيا؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>لا، لا توجد تكلفة مسبقة للانضمام إلى جوڤيا. نحن نعمل بنظام العمولات، حيث تحقق إيرادات مع كل قسيمة يتم شراؤها لتجاربك.</span>
                </div>


                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>كيف أقدم طلب الشراكة؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>ملأ النموذج الموجود في صفحة “كن شريكنا”، وأضف تفاصيل نشاطك التجاري، والتجارب التي تقدمها، ومعلومات الاتصال الخاصة بك. تأكد من إدراج حالة السجل التجاري وشهادة ضريبة القيمة المضافة.</span>
                </div>

                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>ماذا يحدث بعد تقديم طلبي؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>يقوم فريقنا بمراجعة طلبك وتقييم علامتك التجارية بناءً على معايير الجودة والتميز. إذا تم قبول طلبك، سنتواصل معك خلال 3–5 أيام عمل.</span>
                </div>

                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>ما هي الخطوات التالية بعد الموافقة على طلبي؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>بمجرد قبول الطلب، سيتواصل معك أحد أعضاء فريقنا لإتمام العقد. سنقوم أيضًا بجمع جميع المستندات القانونية المطلوبة لإكمال العملية.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>ما الذي يتضمنه الإعداد؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>أثناء الإعداد، سنعمل معك لتصميم العروض التي سيتم عرضها في جوڤيا. ستحتاج إلى تقديم:</span>
                  <ul>
                    <li>صور ومقاطع فيديو عالية الجودة للخدمات، ويفضل أن تتضمن عنصرًا بشريًا.</li>
                    <li>تفاصيل الأسعار والعروض.</li>
                    <li>أي محتوى إضافي يعزز من جودة العرض.</li>

                  </ul>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>كم تستغرق عملية الإعداد؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>تستغرق عملية الإعداد عادةً من 1–2 أسبوع، اعتمادًا على سرعة توفير المواد المطلوبة وإتمام الاتفاقية.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>كيف يمكنني إدارة القسائم والحجوزات؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>بمجرد أن تصبح تجاربك متاحة، ستحصل على وصول إلى لوحة تحكم العلامة التجارية، حيث يمكنك:</span>
                  <ul>
                    <li>التحقق من القسائم وتأكيدها.</li>
                    <li>عرض القسائم الحالية (يمكنك طلب أي تعديلات عبر brands@jovia.gifts).</li>
                    <li>متابعة طلبات الدفع.</li>
                    <li>مراقبة أداء مبيعاتك.</li>
                  </ul>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>كيف سأستلم المدفوعات؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>تتم معالجة المدفوعات مباشرة من خلال لوحة التحكم مرة واحدة شهريًا. ستحتاج إلى تقديم فاتورة واحدة لجميع القسائم التي ترغب في صرفها. ستتلقى مدفوعاتك خلال 3–4 أيام عمل من تقديم الفاتورة، ويمكنك عرض فاتورة ضريبة القيمة المضافة الخاصة بالعمولة.</span>
                </div>


                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>هل يمكنني تحديث أو تغيير عروض التجارب؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>نعم، يمكنك تحديث أو تعديل عروضك في أي وقت. تواصل معنا عبر brands@jovia.gifts، وسنساعدك في إجراء التغييرات اللازمة.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>ما نوع الصور ومقاطع الفيديو المطلوبة؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>ننصح باستخدام صور ومقاطع فيديو عالية الجودة تُظهر خدماتك أثناء التنفيذ. يجب أن تتضمن عنصرًا بشريًا لجعل التجارب أكثر جاذبية وواقعية.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>هل يجب أن أقدم عروضًا حصرية لجوفيا؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>العروض الحصرية ليست إلزامية، لكنها مستحسنة. تقديم عروض فريدة لعملاء جوڤيا يمكن أن يزيد من جاذبيتك ورؤيتك.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>كيف تضمن جوڤيا جودة التجارب؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>نقوم بتقييم جميع الشركاء بعناية للتأكد من توافقهم مع معايير الجودة الخاصة بنا. كما نتابع تعليقات العملاء لضمان الحفاظ على الجودة العالية لجميع التجارب المدرجة.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>كيف يمكنني الحصول على الدعم خلال العملية؟</span><br />
                  <span className='font-ar-text' style={{ fontSize: '1.2rem' }}>إذا كانت لديك أي أسئلة أو تحتاج إلى مساعدة، يمكنك التواصل معنا عبر البريد الإلكتروني brands@jovia.gifts.</span>
                </div>
              </div>
            </div>
          </div>

          <br /><br />



        </div>
      </div>
      <br /><br />
      <br />
    </div>
  );
}

export default AR;

