import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function About() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <div className="row static-row">
          <div className="col-md-12">
            <div className="static-container-card" style={{ textAlign: 'left', padding: '20px', background: '#fff0' }}>
              <h3 style={{ padding: '20px 0px 20px 0px', textAlign: 'center', fontSize: '1.5rem' }}>
                {t('about')}
                <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

