import React, { useEffect, useRef } from 'react';
import './index.scss';
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import AR from './AR';
import EN from './EN';

function Home() {

  const { languageCode } = useSelector((state) => state.auth);
  

  return (
    <>
      {languageCode === 'en' ? <EN /> : <AR />}
    </>
  );
}

export default Home;
