const Languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
    lang: 'en',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
    lang: 'ar',
  }
]

export default Languages