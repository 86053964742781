import React, { useEffect, useState } from 'react';
import './index.scss';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/joy/Button';
import { useSelector } from "react-redux";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
function EN() {
  const { languageCode } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)


  async function createInquiryFrom(data) {
    if (isValidPhoneNumber(getPhone)) {
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <br /><br />
        <div className="row static-row">


          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <p className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'} style={{ fontSize: '1.4rem' }}>
                {languageCode === 'ar' ? 'أخبرنا ما هي التجارب التي ترغب في أن نضيفها على منصتنا' :
                  "Tell us what experiences you'd like us to add on our platform"

                }
              </p>
            </div>
          </div>




          <div className="col-md-10" style={{ padding: '0px' }}>
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0' }}>

              <form
                onSubmit={handleSubmit(createInquiryFrom)}
              >
                {/* <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey=""
                        /> */}

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('first_name')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('last_name')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label" >
                      <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('email')} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-label">
                      <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('phone')} </label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={'SA'}
                        value={getPhone}
                        onChange={setPhone}
                      />
                      {getPhoneError &&
                        <>
                          {getPhone && isValidPhoneNumber(getPhone) ? '' :
                            <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                          }
                        </>
                      }
                    </div>
                  </div>

                </div>


                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-label">
                      <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>
                        {languageCode === 'ar' ?
                          'أخبرنا ما هي التجارب التي ترغب في أن نضيفها على منصتنا' :
                          'Share with us what services and experiences you would like to see on our platform'
                        }
                      </label>

                      <textarea
                        type="text"
                        className="form-control"
                        id="message"
                        aria-invalid={errors.message ? "true" : "false"}
                        {...register('message', { required: true })}>
                      </textarea>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6 Header-signin-button">
                    <div className="form-group">
                      <Button
                        variant='soft'
                        size='sm'
                        // disabled={createresult.isLoading}
                        style={{ width: '50%', height: '45px', marginBottom: '20px' }}
                        type="submit">
                        {/* {createresult.isLoading ? t('loading') : t('submit')} */}
                        <span className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('submit')}</span>
                      </Button>

                    </div>
                  </div>
                </div>
              </form>

              <br /><br />
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <br />
    </div>
  );
}

export default EN;

