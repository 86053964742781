import React, { useEffect } from 'react';
import Header from '../../Components/Header'
import Footer from '../../Pages/Footer'
import i18next from 'i18next'
import { useDispatch, useSelector } from "react-redux";
import { addToLanguage } from "../../Reducers/authReducer";
import languages from './Languages';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from "react-helmet";

function Pages(props) {
  const dispatch = useDispatch();
  const { languageCode } = useSelector((state) => state.auth);
  const Components = props.Components;
  const getLanguage = i18next?.language ? i18next?.language : 'en'
  const currentLanguage = languages?.find((l) => l.code === getLanguage)

  useEffect(() => {
    dispatch(addToLanguage({ languageCode: getLanguage }));
    document.body.dir = currentLanguage.dir || 'ltr'
    document.documentElement.lang = currentLanguage.lang || 'en'
  }, [currentLanguage])

  const lngSwipStyle = {
    textAlign: languageCode === 'ar' ? 'right' : 'left',
    display: 'block',
    minHeight: '100vh',
    position: 'relative',
    fontFamily: languageCode === 'ar' ? '"KhebratMusamim", sans-serif' : '"JannaLT", sans-serif',
  }

 const thisFont = languageCode === 'ar' ? '"KhebratMusamim", sans-serif' : '"JannaLT", sans-serif'

  return (
    <>
      <Helmet>
        <style>{`
          body {
            font-family: ${thisFont};
          }
        `}</style>
      </Helmet>
      <ParallaxProvider>
        <div style={lngSwipStyle}>
          {props.header && <Header />}
          <Components />
          {props.footer && <Footer />}
        </div>
      </ParallaxProvider>
    </>
  );
}

export default Pages;
