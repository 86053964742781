import React, { Component } from 'react';

function NotFound() {
  return (
    <div style={{
      padding: '100px 0px 100px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <h4>PAGE NOT FOUND! 404</h4>
    </div >
  );
}

export default NotFound;
