import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import './index.scss';
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

function EN() {
  const { t } = useTranslation()
  const { languageCode } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })

  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     setAlready(false)
  //   }, 60000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  // function getujjj() {
  //   return (
  //     <video autoplay muted loop width="600">
  //       <source src="https://jovia.my.canva.site/videos/7ee74b9edf5f59d2b7b5653d4c3ea10c.mp4" type="video/mp4">
  //         Your browser does not support the video tag.
  //     </video>
  //   )
  // }
  const [isAvailable, setIsAvailable] = React.useState(false);
  const handleReady = () => {
    setIsAvailable(true);
  };


  return (
    <div className="home-page-section">
      <div className='container'>
        <br /><br />
        <div className='row card-section'>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', lineHeight: 1.3 }}>
                <strong>JOVIA</strong> - Where gifts ​become unforgettable ​experiences</div>
              <p className='font-en-text' style={{ fontSize: '1.4rem', marginBottom: '40px' }}>JOVIA is solving the problem of boring, ​traditional gifts with a unique touch: premium ​experiences that leave a lasting memory.</p>
              <div className='hp-app-icons-section'>
                <div className='hp-app-icon-card'>
                  <img src='/android-icon.svg' />
                </div>
                <div>&nbsp;&nbsp;</div>
                <div className='hp-app-icon-card'>
                  <img src='/apple-icon.svg' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 m-m-top' style={{ textAlign: 'center' }}>
            <img src='/hp-box.png' style={{ width: '100%', position: 'relative', top: '-50px' }} />
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', fontWeight: 600, lineHeight: 1.3 }}>
                Explore Our Unique<br /> ​Gifting Experience</div>
              <div style={{ marginBottom: '40px', }}>
                <p className='font-en-text' style={{ fontSize: '1.4rem' }}>Traditional vouchers don’t feel like a gift. With ​JOVIA’s exclusive unboxing experience, every ​gift turns into a magical moment.</p>
                <p className='font-en-text' style={{ fontSize: '1.4rem' }}>I​magine the excitement as the box is opened, b​utterflies flutter out, revealing a carefully c​rafted experience voucher. More than just a g​ift, it’s a moment of wonder.</p>
              </div>
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              {isAvailable && <img className='hp-video-frame' src='/mobile-frame.png' />}
              <ReactPlayer
                className='hp-video'
                url={!isMobcreen ?
                  '/jovia-video.mp4' :
                  '/jovia-video.mp4'}
                playing={true}
                controls={false}
                loop={true}
                muted={true}
                playsinline={true}
                onReady={handleReady}
              />

            </div>
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'center' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>
                A Gift for Every Occasion</div>
              <p className='font-en-text' style={{ fontSize: '1.8rem', fontWeight: 300 }}>Discover a Wide Range of Premium Experiences</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-00.jpg' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px' }} />
              <div className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>pampering gift ​expereinces</strong></div>
              <p className='font-en-text' style={{ fontSize: '1.1rem' }}>Treat someone special to a ​luxurious spa day and complete relaxation.</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-01.png' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <div className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>Adventure Gift ​expereinces</strong></div>
              <p className='font-en-text' style={{ fontSize: '1.1rem' }}>Thrill your loved ones with ​unforgettable experiences, from ​land to sea.</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-02.png' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <div className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>unique gift ​expereinces</strong></div>
              <p className='font-en-text' style={{ fontSize: '1.1rem' }}>Find thoughtful and unique gifts ​that make every moment ​unforgettable.</p>
            </div>
          </div>
          <div className='col-md-12' style={{ textAlign: 'center', padding: '10px' }}>
            <span style={{ background: '#eee', padding: '10px 20px 10px 20px', borderRadius: '100px', fontSize: '1rem' }}><strong>Discover A​ll</strong></span>
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'center' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>
                Easy to Choose, Easy to Gift</div>
            </div>
          </div>
          <div className='overflow-sec'>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Brows</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-001.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Select</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-002.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Pe​rsonalize</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/hp-step-m.jpg' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Pay</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-004.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Track</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-005.jpeg' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />

        <div className='row card-section' style={{ paddingBottom: '0px' }}>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>Become a Partner</div>
              <div style={{ fontSize: '1.8rem', marginBottom: '40px' }}>Partner with JOVIA and <br />​Grow Your Brand</div>

              <p className='font-en-text' style={{ fontSize: '1.4rem' }}>Join JOVIA and offer your customers something truly ​special – a way to create unforgettable memories. As a ​JOVIA partner, your premium services will be featured ​alongside other unique experiences, giving your brand ​greater visibility to an affluent audience. Together, we’ll ​turn every moment into an extraordinary gift.</p>
              <br />
              <div className='hp-button'>
                <div className='col-md-12' style={{ textAlign: 'start', padding: '10px' }}>
                  <Link className="link-to" to="/partner" style={{ background: '#eee', padding: '10px 20px 10px 20px', borderRadius: '100px', fontSize: '1rem' }}><strong>Become a Partner</strong></Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center' }}>
            <img src='/jovia-055.png' style={{ width: '100%' }} />
          </div>
        </div>
      </div>

      <br /><br />
    </div>

  );
}

export default EN;
