import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function PrivacyPolicy() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <div className="row static-row">
          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '20px', background: '#fff0' }}>

              <p class="last-updated font-en-text">Last Update: July 1, 2024</p>

              <h1 className='font-en-heading'>Terms & Conditions</h1>

              <h2 className='font-en-heading'>Introduction</h2>
              <p className='font-en-text'>Welcome to JOVIA. By purchasing and using our gift experience vouchers, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully.</p>

              <h2 className='font-en-heading'>No Return, Refund, or Exchange Policy</h2>
              <p className='font-en-text'>All sales of gift experience vouchers are final. Each voucher is delivered in a unique gift box experience that, once opened, is considered used. Therefore, JOVIA does not offer returns, refunds, or exchanges for any purchased vouchers.
                In extreme circumstances, we may consider a solution. If you believe you have an exigent circumstance, you must submit a claim and attach proof of the situation. JOVIA will evaluate the claim and consider a solution based on the reason provided.</p>

              <h2 className='font-en-heading'>Voucher Expiry and Reminders</h2>
              <p className='font-en-text'>Each gift experience voucher has a specified expiry date. The recipient of the voucher will be reminded three times prior to the voucher’s expiration. If the voucher is not claimed by the expiry date, it will be forfeited, and no refund or extension will be provided.</p>

              <h2 className='font-en-heading'>Responsibilities of the Gift Giver and Recipient</h2>
              <p className='font-en-text'>Both the gift giver and the recipient are responsible for reading and understanding the terms and conditions associated with each voucher. This includes, but is not limited to, the validity period, usage instructions, and any other specific conditions outlined for each voucher.</p>

              <h2 className='font-en-heading'>Use of Vouchers</h2>
              <p className='font-en-text'>Gift experience vouchers are intended for use by the designated recipient only. They cannot be transferred, except at the discretion of the service provider, who may allow the transfer to another user. Vouchers cannot be redeemed for cash. The recipient must follow the specific instructions provided for redeeming the voucher.</p>

              <h2 className='font-en-heading'>Product Descriptions</h2>
              <p className='font-en-text'>Hadaya AlZakriat Trading Company (JOVIA) attempts to be as accurate as possible. However, much of the product information associated with the Application is provided by third parties, and JOVIA relies on them to be accurate and non-misleading. Accordingly, JOVIA does not warrant that the Application, product descriptions, and other content on this site are complete, accurate, non-misleading, reliable, current, or error-free.</p>

              <h2 className='font-en-heading'>Compatibility with Devices</h2>
              <p className='font-en-text'>Hadaya AlZakriat Trading Company (JOVIA) does not warrant that the Application will be compatible or interoperable with your device or any other hardware, software, or equipment installed on or used in connection with your device.</p>

              <h2 className='font-en-heading'>Changes to the Application</h2>
              <p className='font-en-text'>We may issue upgraded versions of the Application and may automatically upgrade the version of the Application on our website. We may change, expand, and improve the Application. We may at any time cease to continue operating part or all or selectively disable certain aspects of the Application.</p>

              <h2 className='font-en-heading'>Disclaimer of Warranties and Limitation of Liability</h2>
              <p className='font-en-text'>To the full extent permissible by applicable law, Hadaya AlZakriat Trading Company (JOVIA) disclaims all warranties, express or implied, including, but not limited to, implied warranties of non-infringement, merchantability, and fitness for a particular purpose.
                JOVIA does not warrant that the services, JOVIA's servers, or the e-mail you receive as a result of your use of the Application are free of viruses or other harmful components.
                JOVIA will not be liable for any damages of any kind arising from the use of the Application, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.</p>

              <h2 className='font-en-heading'>Indemnification</h2>
              <p className='font-en-text'>You agree to indemnify, defend, and hold us and our partners, agents, officers, directors, employees, subcontractors, successors and assigns, third-party suppliers of information and documents, attorneys, advertisers, product and service providers, and affiliates harmless from any liability, loss, claim, and expense, including reasonable attorney’s fees, related to your violation of these Terms or use of the Application.</p>

              <h2 className='font-en-heading'>Changes to Terms and Conditions</h2>
              <p className='font-en-text'>JOVIA reserves the right to modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. Your continued use of the vouchers after any changes indicates your acceptance of the new terms.</p>

              <h2 className='font-en-heading'>Privacy Policy</h2>
              <p className='font-en-text'>Your use of the Application is also governed by our <a href="#">Privacy Policy</a>. Please review our Privacy Policy for information on how we collect, use, and protect your personal data.</p>

              <h2 className='font-en-heading'>User Accounts</h2>
              <p className='font-en-text'>If you create an account on our Application, you are responsible for maintaining the security of your account and all activities that occur under your account. You must notify JOVIA immediately of any unauthorized use of your account.</p>

              <h2 className='font-en-heading'>Intellectual Property</h2>
              <p className='font-en-text'>All content on the Application, including text, graphics, logos, images, and software, is the property of Hadaya AlZakriat Trading Company (JOVIA) or its licensors and is protected by copyright, trademark, and other intellectual property laws.</p>

              <h2 className='font-en-heading'>Severability</h2>
              <p className='font-en-text'>If any provision of these terms is found to be unenforceable, the remaining provisions will continue to be in effect.</p>

              <h2 className='font-en-heading'>Governing Law</h2>
              <p className='font-en-text'>These terms and conditions are governed by the laws of the Kingdom of Saudi Arabia. Any disputes arising from or related to these terms and conditions will be subject to the exclusive jurisdiction of the courts of the Kingdom of Saudi Arabia.</p>

              <h2 className='font-en-heading'>Governing Language</h2>
              <p className='font-en-text'>These terms and conditions are provided in both English and Arabic. In case of any discrepancy between the two versions, the Arabic version shall prevail.</p>

              <h2 className='font-en-heading'>Contact Information</h2>
              <p className='font-en-text'>For any questions or concerns regarding these terms and conditions, please <a href="mailto:support@jovia.com">contact us</a>.</p>

              <p className='font-en-text'>By purchasing and using JOVIA’s gift experience vouchers, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

