import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function PrivacyPolicy() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <div className="row static-row">
          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '20px', background: '#fff0' }}>
              <p class="last-updated font-ar-text">آخر تحديث: 4 يونيو 2024</p>

              <h1 className='font-ar-heading'>سياسة الخصوصية</h1>

              <h2 className='font-ar-heading'>المعلومات التي نجمعها</h2>

              <ul className='font-ar-text'>
                <li><strong>المعلومات الشخصية:</strong> تتضمن اسمك، عنوان بريدك الإلكتروني، رقم هاتفك، معلومات الدفع، وتفاصيل التوصيل عندما تقوم بعملية شراء أو إنشاء حساب.</li>
                <li><strong>بيانات الاستخدام:</strong> معلومات عن تفاعلاتك مع موقعنا، مثل عنوان IP، نوع المتصفح، والصفحات التي تمت زيارتها.</li>
              </ul>

              <h2 className='font-ar-heading'>كيف نستخدم معلوماتك</h2>

              <ul className='font-ar-text'>
                <li><strong>تقديم الخدمة:</strong> لمعالجة المعاملات، تسليم المنتجات، إدارة حسابك، وتقديم دعم العملاء.</li>
                <li><strong>تحسين الخدمة:</strong> لتحليل بيانات الاستخدام وتعزيز وظائف الموقع وتجربة المستخدم.</li>
                <li><strong>التسويق:</strong> بموافقتك، لإرسال مواد ترويجية، عروض خاصة، وتحديثات. يمكنك إلغاء الاشتراك في أي وقت.</li>
              </ul>

              <h2 className=''>مشاركة معلوماتك</h2>

              <ul className='font-ar-text'>
                <li><strong>مقدمو الخدمات الخارجية:</strong> نشارك المعلومات مع شركاء موثوقين لمعالجة المدفوعات، تسليم المنتجات، تقديم دعم العملاء، والقيام بأنشطة التسويق.</li>
                <li><strong>المتطلبات القانونية:</strong> قد نفصح عن المعلومات للامتثال للالتزامات القانونية، تنفيذ شروطنا وأحكامنا، أو حماية حقوقنا.</li>
              </ul>

              <h2 className='font-ar-heading'>الأمان</h2>

              <p className='font-ar-text'>نقوم بتنفيذ إجراءات أمان قوية، بما في ذلك التشفير والخوادم الآمنة، لحماية بياناتك من الوصول غير المصرح به، التعديل، أو التدمير.</p>

              <h2 className='font-ar-heading'>الكوكيز</h2>

              <p className='font-ar-text'>نستخدم الكوكيز لتخصيص تجربتك، تتبع أنماط الاستخدام، وتحليل حركة المرور. يمكنك إدارة تفضيلات الكوكيز من خلال إعدادات المتصفح الخاص بك.</p>

              <h2 className='font-ar-heading'>حقوقك</h2>

              <ul className='font-ar-text'>
                <li><strong>الوصول:</strong> طلب الوصول إلى المعلومات الشخصية التي نحتفظ بها عنك.</li>
                <li><strong>التصحيح:</strong> طلب تصحيح أي معلومات غير دقيقة أو غير كاملة.</li>
                <li><strong>الحذف:</strong> طلب حذف معلوماتك الشخصية، مع مراعاة الالتزامات القانونية.</li>
                <li><strong>إلغاء الاشتراك:</strong> سحب موافقتك على معالجة البيانات أو إلغاء الاشتراك من الاتصالات التسويقية.</li>
              </ul>

              <h2 className='font-ar-heading'>نقل البيانات الدولية</h2>

              <p className='font-ar-text'>قد ننقل بياناتك إلى دول خارج محل إقامتك، بما في ذلك لمقدمي الخدمات لدينا الموجودين في الخارج. نضمن أن تكون هذه التحويلات متوافقة مع قوانين حماية البيانات السارية.</p>

              <h2 className='font-ar-heading'>خصوصية الأطفال</h2>

              <p className='font-ar-text'>خدماتنا ليست موجهة للأطفال دون سن 13 عامًا، ولا نقوم بجمع المعلومات الشخصية عن الأطفال بشكل مقصود.</p>

              <h2 className='font-ar-heading'>تغييرات على هذه السياسة</h2>

              <p className='font-ar-text'>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنعلمك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة وتحديث تاريخ "آخر تحديث".</p>

              <h2 className='font-ar-heading'>اتصل بنا</h2>

              <p className='font-ar-text'>إذا كان لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية الخاصة بنا أو ممارسات التعامل مع البيانات، يرجى <a href="mailto:support@jovia.com">الاتصال بنا عبر البريد الإلكتروني</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

