import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '',
  userEmail: localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : '',
  languageCode: localStorage.getItem("languageCode") ? localStorage.getItem('languageCode') : 'en',
  countryCode: localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : 'AE',
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.userId = action.payload?.userId
      state.userEmail = action.payload?.userEmail
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken);
    },
    removeToCart(state, action) {
      state.userId = ''
      state.userEmail = ''
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken);
    },

    addToLanguage(state, action) {
      state.languageCode = action.payload?.languageCode
      state.countryCode = action.payload?.countryCode
      localStorage.setItem("languageCode", state.languageCode)
      localStorage.setItem("countryCode", state.countryCode)
    },
    removeToLanguage(state, action) {
      state.languageCode = ''
      localStorage.setItem("languageCode", state.languageCode)
    },

  },
});

export const { addToCart, removeToCart, addToLanguage, removeToLanguage} = authReducer.actions;

export default authReducer.reducer;
