import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/joy/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Languages from '../../Layout/Pages/Languages';
import { addToLanguage } from "../../Reducers/authReducer";
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import Button from '@mui/material/Button';

function Header() {
  const { userId, userToken, userEmail, languageCode, isOffer } = useSelector((state) => state.auth);
  const isLangaugeCode = languageCode ? languageCode : 'en'
  const countryCode = languageCode === 'ar' ? 'SA' : 'GB'

  const thisFont = languageCode === 'ar' ? '"KhebratMusamim", sans-serif' : '"JannaLT", sans-serif'

  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const history = useHistory();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [getLogoSize, setLogoSize] = useState("100px");
  const [isScroll, setScroll] = useState(false);
  const listenScrollEvent = () => {
    window.scrollY > 15 ? setScroll(true) : setScroll(false);
    window.scrollY > 15 ? setLogoSize("100px") : setLogoSize("100px");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);


  const currentLanguage = Languages?.find((l) => l.code === i18next?.language)

  React.useEffect(() => {
    dispatch(addToLanguage({
      languageCode: i18next?.language ? i18next?.language : languageCode ? languageCode : 'en',
    }));
    document.body.dir = currentLanguage?.dir
  }, [i18next?.language])
  const currentLanguageName = Languages?.find((l) => l.code === isLangaugeCode)

  function onClickLang(data) {
    const findLanguage = Languages?.find((l) => l.code === data)
    document.body.dir = findLanguage?.dir || 'ltr'
    i18next.changeLanguage(data)
    dispatch(addToLanguage({ languageCode: data }));
    window.open(`${window.location.pathname}`, '_self');
  }


  function menuList() {
    return (
      <>
        <Button
          style={{ borderRadius: '10px', fontFamily: thisFont }}
          component={Link}
          className="Header-waller-connect1"
          to={`/`}>
          <span className={languageCode === 'ar' ? 'font-ar-text mobile-menu-item-size' : 'font-en-text mobile-menu-item-size'} >{t('Home')}</span>
        </Button>
        {/* <Button
          style={{ borderRadius: '10px' }}
          component={Link}
          className="Header-waller-connect1"
          to={`/about`}>
          <span className="mobile-menu-item-size">{t('about')}</span>
        </Button> */}
        <Button
          style={{ borderRadius: '10px', fontFamily: thisFont }}
          component={Link}
          className="Header-waller-connect1"
          to={`/partner`}>
          <span className={languageCode === 'ar' ? 'font-ar-text mobile-menu-item-size' : 'font-en-text mobile-menu-item-size'}>{t('become_a_partner')}</span>
        </Button>
      </>
    )
  }

  const [getMobMenu, setMobMenu] = useState(false);
  function getMobilemenu() {
    return (
      <div style={{
        width: '235px',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 9999,
        background: '#000',
        height: '100%',
        padding: '10px',
        transition: "all 0.5s",
        borderRight: '0.5px solid #f7ee23'
      }}>
        <div style={{ position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 1111 }}
            onClick={(() => setMobMenu(false))}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
          {menuList()}
        </div>
      </div>
    )
  }


  const list = (anchor) => (
    <Box
      style={{ background: '#000', height: '100%' }}
      className="side-dialogue-section"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Link
        style={{ display: 'block', color: '#fff', padding: '6px 15px', textTransform: 'uppercase', fontFamily: thisFont }}
        className="Header-waller-connect1"
        to={`/`}>
        <span className={languageCode === 'ar' ? 'font-ar-text mobile-menu-item-size' : 'font-en-text mobile-menu-item-size'}>{t('Home')}</span>
      </Link>

      {/* <Link
        style={{ display: 'block', color: '#fff', padding: '6px 15px', textTransform: 'uppercase' }}
        className="Header-waller-connect1"
        to={`/about`}>
        <span className="mobile-menu-item-size">{t('about')}</span>
      </Link> */}

      <Link
        style={{ display: 'block', color: '#fff', padding: '6px 15px', textTransform: 'uppercase', fontFamily: thisFont }}
        className="Header-waller-connect1"
        to={`/partner`}>
        <span className={languageCode === 'ar' ? 'font-ar-text mobile-menu-item-size' : 'font-en-text mobile-menu-item-size'}>{t('become_a_partner')}</span>
      </Link>
    </Box>
  );

  const [getcartDrawer, cartDrawer] = useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    cartDrawer(getcartDrawer)
  }, [getcartDrawer]);

  const [getOffer, setOffer] = useState(false);


  useEffect(() => {
    if (!isOffer) {
      setOffer(true)
    }
  }, []);



  return (
    <>


      <div className="Header-page"
        style={{
          background: isScroll ? '#644b9f' : '#644b9f',
          padding: '5px',
        }}>
        {isMobcreen && getMobMenu &&
          getMobilemenu()
        }
        <div className="Header-menu">
          <div className="container">
            <div className={`Header-menu-list2 Header-menu-list`}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="Header-menu-logoName">
                  <Link to={`/`}>
                    <LazyLoadImage
                      className={isScroll ? `scrolling-img` : `scrolling-img-rev`}
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      src={`/jovia-eng+arb-white.png`}
                      width={!isMobcreen ? getLogoSize : '100px'}
                      style={{ transition: "all 0.5s" }}
                    />
                  </Link>
                </div>
              </div>
              <div className={`Header-menu-account`}>
                {!isMobcreen &&
                  <>
                    {menuList()}
                  </>
                }

                {isMobcreen &&
                  <div>
                    {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
                      <IconButton key={anchor} onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon style={{ color: '#eeed31' }} />
                      </IconButton>
                    ))}
                  </div>
                }


                <Dropdown>
                  <MenuButton
                    variant='plain'
                    style={{ background: '#fff0' }}
                    className="Header-waller-connect1">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'} style={{ textTransform: 'capitalize', color: '#fcfcfc', fontFamily: thisFont }}>
                        {currentLanguageName?.name}
                      </div>
                      <ExpandMoreIcon className="expand-style" style={{ margin: 0, color: '#fcfcfc' }} />
                    </div>
                  </MenuButton>
                  <Menu style={{ minWidth: 'auto', maxWidth: '100%' }}>

                    {Languages?.map((item, i) =>
                      <MenuItem
                        key={i}
                        onClick={() => onClickLang(item.code)}
                        disabled={isLangaugeCode === item.code ? true : false}
                      >
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                              <span className={item.code === 'ar' ? 'font-ar-text' : 'font-en-text'} style={{ fontSize: '1rem' }}>{item.name}</span>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    )}
                  </Menu>
                </Dropdown>

              </div>
            </div>
          </div>
        </div>
      </div>


      <React.Fragment>
        {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
          <Drawer
            className="side-menu-section side-scroll-menu"
            key={anchor}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 1, padding: '0px', textAlign: document.body.dir === 'rtl' ? 'left' : 'right' }}>
              <ModalClose id="close-icon" sx={{ position: 'initial', color: '#eeed31' }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '6px 15px', background: '#000', height: '100%' }}>
              {list(anchor)}
            </div>
          </Drawer >
        ))
        }
      </React.Fragment >
    </>
  );
}

export default Header;

